import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./scss/style.scss";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";
// import Loading from './loading';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConnectionStatus from "./ConnectionStatus";

var role = "";
var userToken = "";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

//Auth Conditions
const isAuthenticated = () => {
  //write your condition here
  const tokenString = sessionStorage.getItem("token");
  const roleString = sessionStorage.getItem("role");
  if (roleString === "undefined") {
    return false;
    //history.push("/login");
  }
  //

  userToken = JSON.parse(tokenString);
  role = JSON.parse(roleString);
  if (userToken != null && role != null) {
    return true;
  } else {
    return false;
  }
};
const urlQuery = window.location.href;
var resetPassword = urlQuery.includes("resetpassword?userid") ? true : false;
const urlParams = urlQuery.substring(urlQuery.indexOf("?") + 1);
const addToUriResetpassword = "/resetpassword?" + urlParams;

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect from="/" to="/dashboard" />
      )
    }
  />
);

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} userRole={role} />
      ) : (
        <Redirect to="/landingPage" />
      )
    }
  />
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ResetPassword = React.lazy(() =>
  import("./views/pages/login/ResetPassword")
);
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const LandingPage = React.lazy(() => import("./views/LandingPage/landingPage"));
const Home = React.lazy(() =>
  import("./views/LandingPage/components/Home/Home")
);
const Feature = React.lazy(() =>
  import("./views/LandingPage/components/Feature/Feature")
);
const Price = React.lazy(() =>
  import("./views/LandingPage/components/Price/Price")
);
const AppReview = React.lazy(() =>
  import("./views/LandingPage/components/AppReview/AppReview")
);
const About = React.lazy(() =>
  import("./views/LandingPage/components/About/About")
);
const Contact = React.lazy(() =>
  import("./views/LandingPage/components/Contact/Contact")
);

const App = () => {
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={loading} //{<Loading />} //If not pass, nothing appears at the time of new version check.
    >
      {/* <ConnectionStatus /> */}
      <HashRouter>
        <React.Suspense fallback={loading}>
          <ToastContainer />
          <Switch>
            <UnauthenticatedRoute
              exact
              path="/landingPage"
              name="Landing Page"
              component={LandingPage}
            />
            <UnauthenticatedRoute
              exact
              path="/home"
              name="Home Page"
              component={Home}
            />
            <UnauthenticatedRoute
              exact
              path="/feature"
              name="Feature Page"
              component={Feature}
            />

            <UnauthenticatedRoute
              exact
              path="/price"
              name="Price Page"
              component={Price}
            />

            <UnauthenticatedRoute
              exact
              path="/AppReview"
              name="App Review"
              component={AppReview}
            />

            <UnauthenticatedRoute
              exact
              path="/About"
              name="About Page"
              component={About}
            />

            <UnauthenticatedRoute
              exact
              path="/Contact"
              name="Contact Page"
              component={Contact}
            />

            <UnauthenticatedRoute
              exact
              path="/login"
              name="Login Page"
              component={Login}
            />
            <UnauthenticatedRoute
              exact
              path="/login"
              name="Login Page"
              component={Login}
            />

            <Route
              exact
              path="/register"
              name="Register Page"
              component={Register}
            />
            <Route
              exact
              path="/resetpassword"
              name="Reset Password"
              component={ResetPassword}
            />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <AuthenticatedRoute path="/" name="Home" component={TheLayout} />
            {!resetPassword && <Redirect from="/" to={addToUriResetpassword} />}
            {addToUriResetpassword && (
              <Redirect from="/" to={addToUriResetpassword} />
            )}
          </Switch>
        </React.Suspense>
      </HashRouter>
    </CacheBuster>
  );
};

export default App;
